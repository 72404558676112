<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="d-flex flex-wrap align-center justify-end">
      <portal to="pageTitle" :disabled="checkMd">
        <div class="font-page-header pr-5 pr-sm-15 mb-md-15">Manage Destinations</div>
      </portal>
      
      <ActionOutlinedButton
        v-if="isManager"
        class="mr-5 mr-md-11 mb-4 mb-sm-15"
        :to="{ name: 'CreateDestination'}"
      >
        Create destination
      </ActionOutlinedButton>
      
      <ActionOutlinedButton
        v-if="isManager"
        class="mr-5 mr-md-11 mb-4 mb-sm-15"
        :to="{ name: 'CreateGroup'}"
      >
        Create group
      </ActionOutlinedButton>
      
      <RefreshButton class="mr-5 mr-md-11 mb-4 mb-sm-15" :disabled="loading" @click="refresh" />
      
      <v-spacer />
      
      <SearchField
        v-model="search"
        class="mb-4 mb-sm-15"
        @changedSearch="refresh"
      />
      
      <SelectOnPage
        class="pagination-container paginationColor--text ml-5 ml-sm-8 mb-4 mb-sm-15"
        :readonly="loading"
        @changeOnPage="refresh"
      />
    </div>
    
    <div class="destination-container">
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options="options"
        :custom-sort="() => items"
        class="elevation-0 borderNone tableBg rounded-20 borderTable"
        outlined
        disable-pagination
        hide-default-footer
        :loader-height="2"
        :header-props="{ sortIcon: '$sortDownIcon' }"
        :mobile-breakpoint="0"
        @update:options="changeOptions"
      >
        <template #no-data>
          <div class="d-flex">
            <span class="font-normal text-left">No data available</span>
          </div>
        </template>
        
        <template #item.name="{ item }">
          <div class="d-flex align-center">
            <span class="font-normal text-no-wrap">{{ item.name }}</span>
            <TooltipAction bottom message="Destination doesn't receive calls right now" :maxWidth="180" v-if="dataWarningStatus[item.slug]">
              <template #activator>
                <v-icon class="pl-5" color="yellow">
                  $systemIcon
                </v-icon>
              </template>
            </TooltipAction>
          </div>
        </template>
        
        <template #item.buyer="{ item }">
          <div class="d-flex align-center" v-if="item['buyer_id']">
            <span class="font-normal text-no-wrap">{{ item.buyer.name }}</span>
            <TooltipAction bottom message="Buyer doesn't receive calls right now" :maxWidth="180" v-if="dataWarningStatusForBuyers[item.buyer.slug]">
              <template #activator>
                <v-icon class="pl-5" color="yellow">
                  $systemIcon
                </v-icon>
              </template>
            </TooltipAction>
          </div>
          <span class="font-small" v-else>-//-</span>
        </template>
        
        <template #item.destination="{ item }">
          {{ item.destination ? `+${item.destination}` : item['sip_point'] }}
        </template>
        
        <template #item.live="{ item }">
          <div class="d-flex justify-center">
            <v-btn
              v-if="selectDestinationSlugForLive !== item.slug"
              class="px-4 not-disabled-color tableBodyText--text"
              :outlined="setConcurrencyUser"
              :text="!setConcurrencyUser"
              depressed
              min-width="54"
              :disabled="!setConcurrencyUser"
              @click="visibleLiveInput(item)"
            >
              <AnimationChangeLive :live="live[item.slug]" :maxConcurrency="item['max_concurrency']"/>
              
              <span v-if="item['max_concurrency']">/ {{ item['max_concurrency'] }}</span>
            </v-btn>
            
            <v-card flat class="d-flex" v-else v-click-outside="hideLiveInput">
              <InputNumberControl
                class="pr-4 minHeight36"
                v-model="selectMaxConcurrency"
                :min="0"
                autofocus
              />
              <ActionButton
                class="px-4"
                height="36"
                min-width="60"
                :loading="loadingStatus"
                @click="saveConcurrency"
              >
                Save
              </ActionButton>
            </v-card>
          </div>
        </template>
        
        <template #item.hourly="{ item }">
          <AnimationChangeCounter
            :counter="getCounter(item.slug, 'hourly', item['cap_on_id'])"
            :cap="getCap(item['hourly_cap'], item['hourly_revenue_cap'], item['cap_on_id'])"
          />
        </template>
        
        <template #item.daily="{ item }">
          <AnimationChangeCounter
            :counter="getCounter(item.slug, 'daily', item['cap_on_id'])"
            :cap="getCap(item['daily_cap'], item['daily_revenue_cap'], item['cap_on_id'])"
          />
        </template>
        
        <template #item.monthly="{ item }">
          <AnimationChangeCounter
            :counter="getCounter(item.slug, 'monthly', item['cap_on_id'])"
            :cap="getCap(item['monthly_cap'], item['monthly_revenue_cap'], item['cap_on_id'])"
          />
        </template>
        
        <template #item.global="{ item }">
          <AnimationChangeCounter
            :counter="getCounter(item.slug, 'global', item['cap_on_id'])"
            :cap="getCap(item['global_cap'], item['global_revenue_cap'], item['cap_on_id'])"
          />
        </template>
        
        <template #item.status_id="{ item }">
          <SwitchSm
            :value="getStatus(item)"
            @input="changeStatus(item)"
            :disabled="!isManager && !pauseTargetsUser"
            :loading="loadingStatus"
            bgColor="switcherYellow"
          />
        </template>
        
        <template #item.actions="{ item }">
          <TooltipAction bottom :message="isManager ? 'Edit' : 'Edit caps'">
            <template #activator>
              <v-icon class="mr-6" @click="openEditDestination(item)">$pencilIcon</v-icon>
            </template>
          </TooltipAction>
          
          <TooltipAction bottom message="Reset caps" v-if="isManager">
            <template #activator>
              <v-icon class="mr-6" @click="openResetCapsDialog(item)">$resetIcon</v-icon>
            </template>
          </TooltipAction>
          
          <TooltipAction bottom message="Delete" v-if="isManager">
            <template #activator>
              <v-icon @click="openDeleteDestinationDialog(item)">$deleteIcon</v-icon>
            </template>
          </TooltipAction>
        </template>
      </v-data-table>

      <TablePagination
        :loading="loading"
        :dataPagination="dataPagination"
        @input="changePage"
      />

      <div :class="dataPagination.lastPage > 1 ? 'pb-4' : 'pb-26'"></div>
    </div>
    
    <DialogConfirm
      v-model="confirmDialog"
      :loading="loadingAction"
      :title="dialogTitle"
      :subTitle="dialogSubTitle"
      :message="dialogMessage"
      @actionSuccess="goToDeleteDestination"
    />
    
    <DialogResetCaps
      v-model="resetCapsDialog"
      :itemId="destinationSlugForResetCaps"
      forWhomAction="Destination"
      @actionSuccess="setLoadCountersInterval"
    />
    
    <DialogEditDestinationForBuyerRole
      v-model="dialogEditDestination"
      :destination="selectedItem"
      @actionSuccess="updateDestination"
    />
  </v-container>
</template>

<script>
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import RefreshButton from '@/components/buttons/RefreshButton'
import ActionButton from '@/components/buttons/ActionButton'
import SearchField from '@/components/inputs/SearchField'
import InputNumberControl from '@/components/inputs/InputNumberControl'
import SelectOnPage from '@/components/select/SelectOnPage'
import TooltipAction from '@/components/tooltip/TooltipAction'
import AnimationChangeLive from '@/components/animation/AnimationChangeLive'
import AnimationChangeCounter from '@/components/animation/AnimationChangeCounter'
import SwitchSm from '@/components/controls/SwitchSm'
import DialogConfirm from '@/components/dialog/DialogConfirm'
import DialogResetCaps from '@/components/dialog/DialogResetCaps'
import DialogEditDestinationForBuyerRole from '@/components/dialog/DialogEditDestinationForBuyerRole'
import TablePagination from '@/components/pagination/TablePagination'

import { mapState, mapGetters } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'
import { getDestinationsForManager, getDestinationsForBuyer, getCountersForManager, getCountersForBuyer, changeDestinationStatus, editDestinationForManager, editDestinationForBuyer, deleteDestination } from '@/api/destination-routes'
import { getBuyerCounters } from '@/api/buyer-routes'
import { getOnPage, changeItemsAfterUpdate, parsePaginationData } from '@/helper/app-helper'

import currency from '@/mixins/currency'

export default {
  name: 'ManageDestinations',
  components: { ActionOutlinedButton, RefreshButton, ActionButton, SearchField, InputNumberControl, SelectOnPage, TooltipAction, AnimationChangeLive, AnimationChangeCounter, SwitchSm, DialogConfirm, DialogResetCaps, DialogEditDestinationForBuyerRole, TablePagination },
  mixins: [currency],
  data: () => ({
    loading: false,
    loadingAction: false,
    loadingStatus: false,
    dialogEditDestination: false,
    items: [],
    dataPagination: {
      page: 1,
      lastPage: 1
    },
    options: {},
    headers: [
      { text: 'NAME', value: 'name', sortId: 'byId' },
      { text: 'BUYER', value: 'buyer', sortId: 'byBuyer' },
      { text: 'DESTINATION', value: 'destination', sortId: 'byDestination' },
      { text: 'LIVE', value: 'live', align: 'center', width: 180, sortId: 'byLive' },
      { text: 'HOURLY', value: 'hourly', align: 'center', sortable: false },
      { text: 'DAILY', value: 'daily', align: 'center', sortable: false },
      { text: 'MONTHLY', value: 'monthly', align: 'center', sortable: false },
      { text: 'GLOBAL', value: 'global', align: 'center', sortable: false },
      { text: 'STATUS', value: 'status_id', align: 'center', width: 85, sortId: 'byStatus' },
      { text: 'ACTIONS', value: 'actions', sortable: false, width: 140, align: 'right' },
    ],
    confirmDialog: false,
    selectedItem: null,
    resetCapsDialog: false,
    destinationSlugForResetCaps: null,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogMessage: null,
    liveInterval: null,
    pauseTargetsUser: true,
    setConcurrencyUser: true,
    setCapacity: true,
    destinationSlug: null,
    loadDestinationCaps: false,
    live: {},
    dataCounters: {},
    dataWarningStatus: {},
    dataWarningStatusForBuyers: {},
    selectDestinationSlugForLive: null,
    selectMaxConcurrency: 0,
    hideTimeout: null,
    serverSorting: null,
    search: null,
    sortTimer: null,
  }),
  computed: {
    ...mapState({
      permissions: state => state.auth.permissions,
    }),
    ...mapGetters({
      isManager: authTypes.getters.IS_MANAGER,
    }),
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
  },
  mounted() {
    if (!this.isManager && !this.setCapacity) {
      this.headers.pop()
    }
  },
  methods: {
    getCounter(slug, name, capOnId) {
      if (this.dataCounters[slug]) {
        return capOnId === 3 ? this.currency(this.dataCounters[slug][name]) : this.dataCounters[slug][name]
      } else {
        return capOnId === 3 ? this.currency(0) : 0
      }
    },
    getCap(cap, revenueCap, capOnId) {
      if (capOnId === 3) {
        return !!revenueCap ? this.currency(revenueCap) : 0
      } else {
        return cap
      }
    },
    changeOptions(options) {
      clearInterval(this.sortTimer)

      this.sortTimer = setInterval(() => {
        this.options = Object.assign({}, options)
      }, 200)

    },
    async loadDestinations() {
      this.loading = true

      const params = {
        page: this.dataPagination.page,
        onPage: getOnPage(),
        search: this.search,
        ...this.serverSorting,
      }

      const { success, payload, message } = this.isManager
        ? await getDestinationsForManager(params)
        : await getDestinationsForBuyer(params)

      if (success) {
        this.dataPagination = parsePaginationData(payload)
        this.items = payload.data
        this.setLoadCountersInterval()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    getStatus(item) {
      return item['status_id'] === 1
    },
    changePage(page) {
      if (page !== this.dataPagination.page) {
        this.dataPagination.page = page
        this.loadDestinations()
      }
      this.$vuetify.goTo(0)
    },
    setLoadCountersInterval() {
      clearInterval(this.liveInterval)
      this.loadCounters()
      this.loadWarningStatusForBuyer()
      this.liveInterval = setInterval(() => {
        if (!!this.serverSorting && this.serverSorting.hasOwnProperty('byLive')) {
          this.loadDestinations()
        } else {
          this.loadCounters()
          this.loadWarningStatusForBuyer()
        }
      }, 15000)
    },
    loadCounters() {
      const params = {
        targets: this.items.map(destination => destination.slug)
      }
      if (params.targets.length) {
        if (this.isManager) {
          this.loadCountersForManager(params)
        } else {
          this.loadCountersForBuyer(params)
        }
      }
    },
    async loadCountersForManager(params) {
      const { success, payload, message } = await getCountersForManager(params)

      if (success) {
        this.parseDataCounters(payload)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    async loadCountersForBuyer(params) {
      const { success, payload, message } = await getCountersForBuyer(params)

      if (success) {
        this.parseDataCounters(payload)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    async loadWarningStatusForBuyer() {
      if (this.isManager) {
        const params = {
          buyers: this.items.reduce((buyers, destination) => {
            if (destination['buyer_id']) {
              if (buyers.every(buyer => buyer !== destination.buyer.slug)) {
                buyers.push(destination.buyer.slug)
              }
            }
            return buyers
          }, [])
        }

        if (params.buyers.length) {
          const { success, payload } = await getBuyerCounters(params)

          if (success) {
            this.parseDataCounters(payload)
          }
        }
      }
    },
    parseDataCounters(dataCounters) {
      if (Array.isArray(dataCounters)) {
        dataCounters.forEach(item => {
          this.live[item.slug] = item['liveCounter']

          this.dataCounters[item.slug] = item['capsCounter']

          this.dataWarningStatus[item.slug] = item['warningStatus']
        })

        this.items = this.items.slice()
      }
    },
    visibleLiveInput(item) {
      this.selectDestinationSlugForLive = item.slug
      this.selectMaxConcurrency = !!item['max_concurrency'] ? +item['max_concurrency'] : 0
    },
    hideLiveInput() {
      this.hideTimeout = setTimeout(() => {
        this.selectDestinationSlugForLive = null
        this.selectMaxConcurrency = 0
      }, 100)
    },
    async saveConcurrency() {
      clearTimeout(this.hideTimeout)

      const destination = this.items.find(item => item.slug === this.selectDestinationSlugForLive)

      this.loadingStatus = true

      const { success, payload, message } = this.isManager
        ? await editDestinationForManager({
          destinationId: this.selectDestinationSlugForLive,
          formData: {
            ...destination,
            'max_concurrency': +this.selectMaxConcurrency ? this.selectMaxConcurrency : null
          }
        })
        : await editDestinationForBuyer({
          destinationId: this.selectDestinationSlugForLive,
          formData: {
            'max_concurrency': +this.selectMaxConcurrency ? this.selectMaxConcurrency : null
          }
        })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.items = changeItemsAfterUpdate(this.items, payload)
        this.hideLiveInput()
        this.setLoadCountersInterval()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingStatus = false
    },
    openEditDestination(destination) {
      if (this.isManager) {
        this.$router.push({ name: 'DetailDestination', params: { slug: destination.slug }})
      } else {
        this.openDialogEditDestinationForBuyerRole(destination)
      }
    },
    openDialogEditDestinationForBuyerRole(destination) {
      this.dialogEditDestination = true
      this.selectedItem = destination
    },
    updateDestination(updatedDestination) {
      this.items = changeItemsAfterUpdate(this.items, updatedDestination)
    },
    openResetCapsDialog(destination) {
      this.resetCapsDialog = true
      this.destinationSlugForResetCaps = destination.slug
    },
    openDeleteDestinationDialog(destination) {
      this.selectedItem = destination
      this.dialogTitle = 'Delete Destination'
      this.dialogSubTitle = `${destination.name}`
      this.dialogMessage = 'Are you sure you want to delete this Destination?'
      this.confirmDialog = true
    },
    async goToDeleteDestination() {
      this.loadingAction = true

      const { success, message } = await deleteDestination(this.selectedItem.slug)

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.confirmDialog = false
        this.refreshDataAfterDelete()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    async changeStatus(destination) {
      this.loadingStatus = true

      const params = {
        destinationId: destination.slug,
        formData: {
          status: destination['status_id'] === 1 ? 2 : 1
        }
      }

      const { success, payload, message } = this.isManager
        ? await changeDestinationStatus(params)
        : await editDestinationForBuyer(params)

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.items = changeItemsAfterUpdate(this.items, payload)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingStatus = false
    },
    refreshDataAfterDelete() {
      if (this.items.length === 1 && this.dataPagination.page !== 1) {
        --this.dataPagination.page
      }

      this.loadDestinations()
    },
    refresh() {
      this.dataPagination.page = 1
      this.loadDestinations()
    },
  },
  watch: {
    options: {
      deep: true,
      handler(sortOptions) {
        clearInterval(this.sortTimer)

        if (sortOptions.sortBy.length) {
          const sortBy = this.headers.find(item => item.value === sortOptions.sortBy[0])
          this.serverSorting = { [sortBy.sortId]: sortOptions.sortDesc[0] ? 'asc' : 'desc' }
        } else {
          this.serverSorting = null
        }
        this.loadDestinations()
      },
    },
    permissions: {
      immediate: true,
      deep: true,
      handler(items) {
        if(
          !!items
          && items.hasOwnProperty('pause_targets')
          && items.hasOwnProperty('set_concurrency')
          && items.hasOwnProperty('set_capacity')
        ) {
          this.pauseTargetsUser = !!items['pause_targets']
          this.setConcurrencyUser = !!items['set_concurrency']
          this.setCapacity = !!items['set_capacity']
        }
      }
    },
    selectDestinationSlugForLive: {
      handler() {
        clearTimeout(this.hideTimeout)
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.liveInterval)
  },
}
</script>
