<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    max-width="540px"
    persistent
    overlay-color="overlayColor"
  >
    <v-card class="cardBg py-18 px-20 pt-sm-23 pr-sm-35 pb-sm-35 pl-sm-0">
      <v-row no-gutters>
        <v-col cols="12" sm="7" class="pb-15 pr-11">
          <div class="font-large-title text-uppercase dialogTitle--text text-sm-right">
            Update destination
          </div>
        </v-col>
      </v-row>
      <v-form class="pb-20" ref="form">
        <v-row no-gutters v-if="capByRevenue">
          <v-col
            class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
            cols="12" sm="7"
          >Global Revenue Cap, $</v-col>
    
          <v-col class="pb-10" cols="12" sm="5">
            <SwitchAndNumberInput
              v-model="revenueCap['global_revenue_cap']"
              :min="0"
              :step="0.01"
            />
          </v-col>
    
          <v-col
            class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
            cols="12" sm="7"
          >Monthly Revenue Cap, $</v-col>
    
          <v-col class="pb-10" cols="12" sm="5">
            <SwitchAndNumberInput
              v-model="revenueCap['monthly_revenue_cap']"
              :min="0"
              :step="0.01"
            />
          </v-col>
    
          <v-col
            class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
            cols="12" sm="7"
          >Daily Revenue Cap, $</v-col>
    
          <v-col class="pb-10" cols="12" sm="5">
            <SwitchAndNumberInput
              v-model="revenueCap['daily_revenue_cap']"
              :min="0"
              :step="0.01"
            />
          </v-col>
    
          <v-col
            class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
            cols="12" sm="7"
          >Hourly Revenue Cap, $</v-col>
    
          <v-col class="pb-10" cols="12" sm="5">
            <SwitchAndNumberInput
              v-model="revenueCap['hourly_revenue_cap']"
              :min="0"
              :step="0.01"
            />
          </v-col>
        </v-row>
  
        <v-row no-gutters v-else>
          <v-col
            class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
            cols="12" sm="7"
          >Global Cap</v-col>
    
          <v-col class="pb-10" cols="12" sm="5">
            <SwitchAndNumberInput v-model="capacity['global_cap']" :min="0" />
          </v-col>
    
          <v-col
            class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
            cols="12" sm="7"
          >Monthly Cap</v-col>
    
          <v-col class="pb-10" cols="12" sm="5">
            <SwitchAndNumberInput v-model="capacity['monthly_cap']" :min="0" />
          </v-col>
    
          <v-col
            class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
            cols="12" sm="7"
          >Daily Cap</v-col>
    
          <v-col class="pb-10" cols="12" sm="5">
            <SwitchAndNumberInput v-model="capacity['daily_cap']" :min="0" />
          </v-col>
    
          <v-col
            class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
            cols="12" sm="7"
          >Hourly Cap</v-col>
    
          <v-col class="pb-10" cols="12" sm="5">
            <SwitchAndNumberInput v-model="capacity['hourly_cap']" :min="0" />
          </v-col>
        </v-row>
      </v-form>
      
      <div class="d-flex justify-sm-end">
        <CancelButton @click="closeDialog">Cancel</CancelButton>
    
        <ActionButton class="ml-5 ml-sm-11" :loading="loading" @click="submitForm">Save</ActionButton>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import SwitchLg from '@/components/controls/SwitchLg'
import SwitchAndNumberInput from '@/components/controls/SwitchAndNumberInput'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'

import { editDestinationForBuyer } from '@/api/destination-routes'

export default {
  name: 'DialogEditDestinationForBuyerRole',
  inheritAttrs: false,
  components: { SwitchLg, SwitchAndNumberInput, CancelButton, ActionButton },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    destination: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    loading: false,
    capByRevenue: false,
    capacity: {
      'global_cap': null,
      'monthly_cap': null,
      'daily_cap': null,
      'hourly_cap': null,
    },
    revenueCap: {
      'global_revenue_cap': null,
      'monthly_revenue_cap': null,
      'daily_revenue_cap': null,
      'hourly_revenue_cap': null,
    },
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
    parseProps() {
      Object.keys(this.capacity).forEach(name => {
        this.capacity[name] = this.destination[name]
      })
      
      Object.keys(this.revenueCap).forEach(name => {
        this.revenueCap[name] = this.destination[name]
      })
    },
    async submitForm() {
      this.loading = true
      
      const formData = {
        caps: {
          ...this.capacity,
          ...this.revenueCap
        }
      }
      
      Object.keys(formData.caps).forEach(name => {
        if (!formData.caps[name]) {
          formData.caps[name] = null
        }
      })
      
      const { success, payload, message } = await editDestinationForBuyer({
        destinationId: this.destination.slug,
        formData
      })
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.$emit('actionSuccess', payload)
        this.closeDialog()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loading = false
    },
    closeDialog() {
      this.show = false
    },
  },
  watch: {
    show: {
      handler(value) {
        if (value) {
          this.parseProps()
        }
      }
    }
  },
}
</script>
